html{-moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; -ms-overflow-style: scrollbar;}
*, *:before, *:after {-moz-box-sizing: inherit; -webkit-box-sizing: inherit; box-sizing: inherit;}
article,aside,blockquote,body,details,dl,fieldset,figcaption,figure,footer,form,header,hgroup,legend,main,menu,nav,ol,p,section,summary,ul {display: block;margin: 0;padding:0;border:0;}
audio,canvas,video {display: inline-block;display: inline;zoom: 1;}
audio:not([controls]) {display: none;height: 0;}
body {margin: 0;padding: 0;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;}
a{text-decoration: none;cursor: pointer;color:inherit;}
a:active,a:hover {outline: 0;}
h1, h2, h3, h4, h5, h6 {margin: 0;padding: 0;font-weight:normal;}
abbr[title] {border-bottom: 1px dotted;}
b,strong {font-weight: bold;}
dfn {font-style: italic;}
hr {-moz-box-sizing: content-box;box-sizing: content-box;height: 0;}
small {font-size: .9em;}
dl,menu,ol,ul {margin: 0;}
dd {margin: 0;}
iframe{border:none;max-width: 100%;overflow: hidden;}
ul,ol {-webkit-margin-before: 0;-webkit-margin-after: 0;-webkit-margin-start: 0;-webkit-margin-end: 0;-webkit-padding-start: 0;-moz-margin-before: 0;-moz-margin-after: 0;-moz-margin-start: 0;-moz-margin-end: 0;-moz-padding-start: 0;}
img {max-width:100%;height: auto;border: 0; margin: 0; padding: 0; vertical-align: middle;}
svg:not(:root) {overflow: hidden;}
legend {white-space: normal;*margin-left: 0}
button,input,select,textarea {font-size: 100%; margin: 0;padding:0;vertical-align: baseline; /* 3 */*vertical-align: middle; /* 3 */}
button,input {line-height: normal;}
button,select {text-transform: none;}
button,html input[type="button"],input[type="reset"],input[type="submit"] {-webkit-appearance: button; cursor: pointer;*overflow: visible;}
button[disabled],html input[disabled] {cursor: default;}
label::selection {background-color:transparent;}
input[type="checkbox"],input[type="radio"] {box-sizing: border-box; padding: 0; *height: 1em; *width: 1em;}
input[type="search"] {-webkit-appearance: textfield; -moz-box-sizing: content-box;-webkit-box-sizing: content-box; /* 2 */box-sizing: content-box;}
input[type="search"]::-webkit-search-cancel-button,input[type="search"]::-webkit-search-decoration {-webkit-appearance: none;}
button::-moz-focus-inner,input::-moz-focus-inner {border: 0;padding: 0;}
textarea {display: block; overflow: auto;vertical-align: top;}
table {border-collapse: collapse;border-spacing: 0;}

[hidden] {display: none;}
.clearfix:after{content: "";display: table;float: none;clear: both;}