.main--home {
	padding-top: $padding-base * 3;
}

.main {
	background-color: $off-white;
	padding-bottom: $padding-base * 3;
	border-bottom: 1px solid $grey-warm-darker;
}

.secondary-content {
	.detail {
		padding: $padding-base $padding-base * 2 $padding-base * 3 $padding-base * 2;
		border-left: 0;
    border-right: 0;
		@media(max-width:$breakpoint-max-md) {
			padding:$padding-base * 2 $padding-base $padding-base * 3;
		}
	}
}

.page {
	clear: both;
	margin: 0 auto;
	border-left: solid 1px #E5E5E5;
	border-right: solid 1px #E5E5E5;
	border-bottom: solid 1px #E5E5E5;
	background-color: #f2f2f2;
	padding-bottom: 4.5rem;
}

//bootstrap overrides

//TODO: revise this to be less fragile with a classname

.secondary-content .container {
	padding: $padding-base * 1.5 $padding-base;
}

.detail-template  {
	margin-top: $padding-base;
	.col-lg-9:first-child {
		@media (min-width: $min-992) {
			//To account for right rail.
			max-width: calc(100% - 33rem);
			flex: 0 0 calc(100% - 33rem);
		}
	}
}

.detail-template--marginRight {
	@media(min-width: $min-1200) {
		.col-lg-9 {
			max-width: 72%;
			flex: 0 0 72%;
			margin-right: $padding-base;
		}
	}
}


hr {
	&.divider__grey {
		//bs override
		display: block;
		margin-bottom: 0 !important;
		margin-top: $padding-base;
		padding-bottom: $padding-base;
	}
}

  .article__list {
    list-style: none;
    padding-left: 0;
  }

  .article__item {
    padding-bottom: 1.6rem;
  }
