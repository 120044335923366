//@import url("https://fonts.googleapis.com/css2?family=Open+Sans");

// you have access to all globalSCSSvarsAndMixins thanks to config
//:root {
//	font-size: 10px;
//}
//
//body {
//	font-family: $systemFontStackBody;
//	font-size: 1.6rem;
//	// slight difference from prod to achieve integer line height
//	// this calculates to line height 22px (prod is 23.8571)
//	line-height: 1.375;
//	color: $body-copy-color;
//}
//
//h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
//	font-family: $systemFontStackHeadlines;
//	// Arial Black doesNt need bold
//	// But it has no negative impact
//	font-weight: bold;
//	color: $headline-color;
//	margin-bottom: 0;
//	padding-bottom: 1rem;
//	padding-top: $padding-base * 1.5;
//}

//h1, .h1 {
//	font-size: 3.2rem;
//}

//h2, .h2 {
//	font-size: 3rem;
//}

//h3, .h3 {
//	font-size: 2.4rem;
	//this letterspacing is ONLY found on H3
//	letter-spacing: -1px;
//}

//h4, .h4{
//	font-size: 1.8rem;
//}

//h5, .h5 {
//	font-size: 1.4rem;
//}

//h6, .h6 {
//	font-size: 1.2rem;
//}

a {
	//color: $link-default;
	transition: $transition-color-only;
	//text-decoration: none;
	&:hover {
		//color: $primary-blue-1;
		//text-decoration: none;
	}
	&:visited {
		//color: $link-visited;
		&:hover {
			//color: $primary-blue-1;
		}
		h1 &,
		h2 &,
		h3 &,
		h4 &,
		h5 &,
		h6 & {
			color: inherit;
		}
	}
	&.has-arrow {
		&:after {
			content:"\f0da";
			font-family: 'Font Awesome 5 Free';
			padding-left:0.4rem;
			font-weight: 900;
		}
	}
}

//p {
//	margin: 0;
//	padding-bottom: 1rem;
//}

ul, ol {
	list-style-position: outside;
	padding-bottom: 1rem;
}

ol>ol {
	list-style-type: upper-roman;
}

.no_bullet {
	list-style: none;
}

.card {
	&.detail {
		ul, ol {
			padding-left: 2.5rem;
		}
	}
}

.header__noPadding {
	padding: 0;
	margin: 0;
}

.header--blue {
	background: $primary-blue-1;
	color:#fff;
}

.p--black,
.p--black strong {
	color: #000;
}

// webfontloader visibility override for ie & edge
@media screen and (-ms-high-contrast: none) {
	.fas, .far, .fab { visibility: visible; }
}
@supports (-ms-ime-align:auto) {
	.fas, .far, .fab { visibility: visible; }
}