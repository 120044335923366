//.table--sortable {
//	thead {
//		th {
//			font-size: 1.4rem;
//			&:not(.sorting_disabled) {
//				position:relative;
//				padding: .75rem 1.55rem .75rem .75rem;
//				&:not(.sort_asc):not(.sort_dsc) {
//					&:after {
//						font-family: 'Font Awesome 6 Free';
//						content:"\f0dc";
//						padding-left:0.8rem;
//						color: $light-icons;
//						font-size: 0.8em;
//						position:absolute;
//						right: .4rem;
//						font-weight: 900;
//					}
//				}
//				&.sort_asc {
//					&:after {
//						font-family: 'Font Awesome 6 Free';
//						content:"\f0de";
//						padding-left:0.8rem;
//						color: $icon-blue;
//						font-size: 0.8em;
//						position:absolute;
//						right: .4rem;
//						font-weight: 900;
//					}
//				}
//				&.sort_dsc {
//					&:after {
//						font-family: 'Font Awesome 6 Free';
//						content:"\f0dd";
//						padding-left:0.8rem;
//						color: $icon-blue;
//						font-size: 0.8em;
//						position:absolute;
//						right: .4rem;
//						font-weight: 900;
//					}
//				}
//			}
//		}
//	}
//}
//
//
////table odd item color
//.table-striped tbody tr:nth-of-type(odd) {
//	background-color: #f9f9f9
//}
//
//.custom-responsive {
//	tr {
//		td {
//			@media (max-width: $breakpoint-max-sm) {
//				display:block;
//				//bs override;
//				width: 100% !important;
//			}
//		}
//	}
//}
//
//.responsive-table {
//	@media (max-width: $breakpoint-max-sm) {
//		thead {
//			display: none;
//		}
//		table,
//		tbody,
//		th,
//		td,
//		tr {
//			display: block;
//		}
//		table {
//			border: 1px solid #ccc;
//		}
//		tr {
//			&:nth-child(even){
//				background-color: #eeeeee;
//			}
//		}
//	}
//}
//
//.colored-tableheader {
//	tr {
//		background-color: #a1a19f;
//		a {
//			color: #fff;
//			text-decoration: underline;
//		}
//	}
//}

.module-box {
	background-color: $module-box-bg;
}

.seminar-box {
	background-color: $seminar-box-bg;
}


.table__head--blue {
	background-color: $primary-blue-1;
	color: #fff;
}


.table--noResults {
	padding: 0;
	th {
		border-width: 0 !important;
	}
	tr {
		border-width: 0 !important;
		padding: 0 !important;
	}
	td {
		border-width: 0 !important;
		padding: 0 !important;
	}
}

//for spans and such inside tables
.noResults {
	display: block;
	background-color: $primary-yellow;
	color: #333;
	padding: 1.6rem;
	p {
		display: inline;
	}
}

.noResults--notInTable {
	margin-bottom: 2rem;
}

.table-bottom-link {
	a {
		~ a {
			margin-left: 1.6rem;
		}
	}
}

.table-col {
	&--first{
		min-width: 13rem;
	}

	&--25{
		@media (min-width: $min-992){
			width: 25%;
		}
	}

	&--30{
		@media (min-width: $min-992){
			width: 30%;
		}
	}

	&--50 {
		@media (min-width: $min-992) {
			width: 50%;
		}
	}

	&--15{
		@media (min-width: $min-992){
			width: 15%;
		}
	}
}