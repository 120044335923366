@media (min-width: 992px) {
  .hidden-lg-up {
    display: none;
  }
}

@media (max-width: 991px) {
  .hidden-lg-down {
    display: none;
  }
}

html {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*, *:before, *:after {
  -moz-box-sizing: inherit;
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

article, aside, blockquote, body, details, dl, fieldset, figcaption, figure, footer, form, header, hgroup, legend, main, menu, nav, ol, p, section, summary, ul {
  display: block;
  margin: 0;
  padding: 0;
  border: 0;
}

audio, canvas, video {
  display: inline-block;
  display: inline;
  zoom: 1;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
}

a:active, a:hover {
  outline: 0;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b, strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}

small {
  font-size: 0.9em;
}

dl, menu, ol, ul {
  margin: 0;
}

dd {
  margin: 0;
}

iframe {
  border: none;
  max-width: 100%;
  overflow: hidden;
}

ul, ol {
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  -webkit-padding-start: 0;
  -moz-margin-before: 0;
  -moz-margin-after: 0;
  -moz-margin-start: 0;
  -moz-margin-end: 0;
  -moz-padding-start: 0;
}

img {
  max-width: 100%;
  height: auto;
  border: 0;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}

svg:not(:root) {
  overflow: hidden;
}

legend {
  white-space: normal;
  *margin-left: 0;
}

button, input, select, textarea {
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline; /* 3 */
  *vertical-align: middle; /* 3 */
}

button, input {
  line-height: normal;
}

button, select {
  text-transform: none;
}

button, html input[type=button], input[type=reset], input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer;
  *overflow: visible;
}

button[disabled], html input[disabled] {
  cursor: default;
}

label::selection {
  background-color: transparent;
}

input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0;
  *height: 1em;
  *width: 1em;
}

input[type=search] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box; /* 2 */
  box-sizing: content-box;
}

input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  display: block;
  overflow: auto;
  vertical-align: top;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

[hidden] {
  display: none;
}

.clearfix:after {
  content: "";
  display: table;
  float: none;
  clear: both;
}

.main--home {
  padding-top: 4.8rem;
}

.main {
  background-color: #f2f2f2;
  padding-bottom: 4.8rem;
  border-bottom: 1px solid #E5E5E5;
}

.secondary-content .detail {
  padding: 1.6rem 3.2rem 4.8rem 3.2rem;
  border-left: 0;
  border-right: 0;
}
@media (max-width: 1023px) {
  .secondary-content .detail {
    padding: 3.2rem 1.6rem 4.8rem;
  }
}

.page {
  clear: both;
  margin: 0 auto;
  border-left: solid 1px #E5E5E5;
  border-right: solid 1px #E5E5E5;
  border-bottom: solid 1px #E5E5E5;
  background-color: #f2f2f2;
  padding-bottom: 4.5rem;
}

.secondary-content .container {
  padding: 2.4rem 1.6rem;
}

.detail-template {
  margin-top: 1.6rem;
}
@media (min-width: 992px) {
  .detail-template .col-lg-9:first-child {
    max-width: calc(100% - 33rem);
    flex: 0 0 calc(100% - 33rem);
  }
}

@media (min-width: 1200px) {
  .detail-template--marginRight .col-lg-9 {
    max-width: 72%;
    flex: 0 0 72%;
    margin-right: 1.6rem;
  }
}

hr.divider__grey {
  display: block;
  margin-bottom: 0 !important;
  margin-top: 1.6rem;
  padding-bottom: 1.6rem;
}

.article__list {
  list-style: none;
  padding-left: 0;
}

.article__item {
  padding-bottom: 1.6rem;
}

.module-box {
  background-color: #eef7f9;
}

.seminar-box {
  background-color: #c8e5ec;
}

.table__head--blue {
  background-color: #024c7b;
  color: #fff;
}

.table--noResults {
  padding: 0;
}
.table--noResults th {
  border-width: 0 !important;
}
.table--noResults tr {
  border-width: 0 !important;
  padding: 0 !important;
}
.table--noResults td {
  border-width: 0 !important;
  padding: 0 !important;
}

.noResults {
  display: block;
  background-color: #fdcd06;
  color: #333;
  padding: 1.6rem;
}
.noResults p {
  display: inline;
}

.noResults--notInTable {
  margin-bottom: 2rem;
}

.table-bottom-link a ~ a {
  margin-left: 1.6rem;
}

.table-col--first {
  min-width: 13rem;
}
@media (min-width: 992px) {
  .table-col--25 {
    width: 25%;
  }
}
@media (min-width: 992px) {
  .table-col--30 {
    width: 30%;
  }
}
@media (min-width: 992px) {
  .table-col--50 {
    width: 50%;
  }
}
@media (min-width: 992px) {
  .table-col--15 {
    width: 15%;
  }
}

img.imgLeft,
img.figure--left {
  float: left;
  max-width: 38.4rem;
  margin-right: 1.2rem;
  height: auto;
}

img.imgRight,
img.figure--right {
  float: right;
  max-width: 38.4rem;
  margin-left: 1.2rem;
  height: auto;
}

.red-text,
.red-text a {
  color: #c71d28;
}

a {
  transition: color 0.5s ease;
}
h1 a:visited, h2 a:visited, h3 a:visited, h4 a:visited, h5 a:visited, h6 a:visited {
  color: inherit;
}
a.has-arrow:after {
  content: "\f0da";
  font-family: "Font Awesome 5 Free";
  padding-left: 0.4rem;
  font-weight: 900;
}

ul, ol {
  list-style-position: outside;
  padding-bottom: 1rem;
}

ol > ol {
  list-style-type: upper-roman;
}

.no_bullet {
  list-style: none;
}

.card.detail ul, .card.detail ol {
  padding-left: 2.5rem;
}

.header__noPadding {
  padding: 0;
  margin: 0;
}

.header--blue {
  background: #024c7b;
  color: #fff;
}

.p--black,
.p--black strong {
  color: #000;
}

@media screen and (-ms-high-contrast: none) {
  .fas, .far, .fab {
    visibility: visible;
  }
}
@supports (-ms-ime-align: auto) {
  .fas, .far, .fab {
    visibility: visible;
  }
}
[v-cloak] {
  display: none;
}

.bgImg--cover {
  background-size: cover;
  background-position: 50% 50%;
}

.hiddeng {
  display: none;
}

@media (min-width: 768px) {
  .container {
    max-width: 75rem;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 97rem;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 117rem;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 120rem;
  }
}
@media (max-width: 767px) {
  .container {
    max-width: none;
  }
}

.container-back {
  background-color: #ddd;
  padding: 1.6rem;
}

.scrollable {
  overflow: auto;
}

.hidden {
  display: none !important;
}

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-md {
    display: none !important;
  }
}

@media (max-width: 1199px) {
  .hidden-md-down {
    display: none !important;
  }
}

@media (max-width: 1365px) {
  .hidden-1366-down {
    display: none !important;
  }
}

@media (min-width: 1366px) {
  .hidden-1366 {
    display: none !important;
  }
}

.pad-4 {
  margin-left: 0.4rem;
  margin-right: 0.4rem;
}

.search__autocomplete {
  flex: 1;
  position: relative;
}

[data-header] .row {
  margin-left: 0;
  margin-right: 0;
}
[data-header] .nav .bf-dropdown-menu a,
[data-header] .nav .dropdown-menu a {
  display: block;
  padding-top: 0;
}
[data-header] .nav__link {
  padding: 0.8rem;
}
[data-header] .nav__link:not(.button) {
  color: #333;
}
[data-header] .l1__item.search {
  margin-bottom: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}
[data-header] .l1__item:not(.search) {
  font-size: 1.5rem;
  padding: 0.8rem;
}
[data-header] .l1__item:not(.search):last-child {
  padding-right: 0;
}
@media (max-width: 1365px) {
  [data-header] .l1__item:not(.search) {
    padding: 0;
  }
  [data-header] .l1__item:not(.search) > a {
    padding: 1.6rem;
    display: block;
    font-size: 1.8rem;
    color: #000;
  }
  [data-header] .l1__item:not(.search).dropdown, [data-header] .l1__item:not(.search).bf-dropdown, [data-header] .l1__item:not(.search).utility {
    font-weight: 700;
  }
}
[data-header] .l1__item.account__item {
  padding: 0.8rem 0.2rem;
}
[data-header] .container {
  padding: 0;
}
[data-header] .actuarial-dropdown {
  top: calc(100% - 1.6rem);
  width: 40rem;
  left: 30%;
  font-size: 1.4rem;
  border-bottom: 5px solid #bdbaba;
  padding-top: 1.6rem;
}
[data-header] .actuarial-dropdown .form-group {
  padding-bottom: 0.8rem;
}
[data-header] .actuarial-dropdown .search-by__label {
  font-weight: bold;
}
[data-header] .actuarial-dropdown .search-by__label i {
  color: #babf33;
  padding-right: 0.4rem;
}
[data-header] .actuarial-dropdown .button {
  padding: 0.6rem 2.4rem;
  font-weight: 400;
}
[data-header] .actuarial-dropdown .form--footer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
[data-header] .actuarial-dropdown a.form--header__link {
  padding-bottom: 1.6rem;
}
[data-header] .actuarial-dropdown a.form--footer__link,
[data-header] .actuarial-dropdown a.form--header__link {
  display: inline-block;
  color: #0090bc;
}
[data-header] .actuarial-dropdown a.form--footer__link:hover,
[data-header] .actuarial-dropdown a.form--header__link:hover {
  color: #2a6496;
}
[data-header] .search-bar {
  justify-content: flex-end;
  width: 68%;
}
[data-header] .search-bar input {
  border-radius: 0;
  height: 100%;
}
@media (max-width: 1365px) {
  [data-header] .search-bar {
    width: 100%;
  }
}
[data-header] .input-group-addon {
  color: #555;
}
[data-header] .input-group-addon button {
  color: #555;
}
[data-header] .collapse {
  transition: all 0.3333s cubic-bezier(1, 0, 0, 1);
  height: auto;
}
[data-header] .collapse.in, [data-header] .collapse.show {
  max-height: 10000px;
}
[data-header] .collapse:not(.show):not(.in) {
  max-height: 0;
  display: block;
  overflow: hidden;
}
@media (min-width: 1366px) {
  [data-header] .collapse,
  [data-header] .collapse:not(.show):not(.in) {
    height: auto;
    max-height: 10000px;
    display: block;
  }
}

.search-bar {
  position: relative;
}

.search-bar__form {
  display: flex;
  flex: 1;
  font-size: 1.4rem;
}
.search-bar__form .input-group-addon {
  border-radius: 0;
}

#page {
  left: 0px;
  transition: all 0.5s ease;
}

.nav {
  list-style: none;
  display: block;
}
.nav li {
  display: inline-block;
}
.nav.mobile-header {
  padding: 1rem;
}
@media (max-width: 767px) {
  .nav.mobile-header {
    padding: 0;
  }
}
@media (min-width: 1366px) {
  .nav.mobile-header {
    display: none !important;
  }
}
.nav.mobile-header button {
  padding: 0.8rem 1.2rem;
  margin: 0 0.8rem;
  background-color: #ccc;
  border-color: #ccc;
}
.nav.mobile-header button:hover {
  background-color: #ccc;
  border-color: rgb(208, 191, 165);
}
@media (max-width: 1365px) {
  .nav.sidebar-md {
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 250px;
    left: -250px;
    top: 0;
    bottom: 0;
    transition: all 0.5s ease;
    flex-wrap: nowrap;
    overflow-y: auto;
  }
}
.nav.sidebar-md .search-link {
  padding-inline: 1.6rem;
}
.nav.sidebar-md button.close {
  padding: 1rem 1.6rem;
  color: #fff;
  text-shadow: none;
  opacity: 1;
  min-height: 3.6rem;
  max-width: 80%;
}
.nav.sidebar-md.show {
  left: 0;
  z-index: 10;
}

.input-group-addon {
  padding: 0.6rem 1.2rem;
  background-color: #ccc;
}
.input-group-addon:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.input-group-addon.search-button {
  border-radius: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-left: none;
  transition: all 0.5s ease;
}
.input-group-addon.search-button button {
  transition: all 0.5s ease;
  background-color: #fff;
  border: none;
}
.input-group-addon.search-button:hover {
  background-color: #eee;
}
.input-group-addon.search-button:hover button {
  background-color: #eee;
}

.form-control {
  height: 3.4rem;
  font-size: 1.4rem;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  border: 1px solid #ccc;
}

.bf-dropdown-menu,
.dropdown-menu {
  width: 100%;
  font-size: 1.6rem;
  padding: 3.2rem 3.2rem 2.4rem;
}

[data-homepageheroblock] h1 {
  color: #fff;
}

.row--hero {
  width: 100%;
}
@media (max-width: 991px) {
  .row--hero {
    margin: 0;
  }
}

@media (max-width: 991px) {
  .column--hero {
    padding: 0;
  }
}

.hero__image__wrapper {
  padding: 1.6rem;
  min-height: 40rem;
  height: 1px;
  display: flex;
  align-items: center;
  position: relative;
}
@media (max-width: 767px) {
  .hero__image__wrapper {
    padding: 0;
    min-height: 0;
    height: auto;
  }
  .hero__image__wrapper[style] {
    background-image: none !important;
  }
}
.hero--explorer .hero__image__wrapper {
  padding: 0;
}
.hero__image__wrapper.page-edit-pseudo {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.hero--homepage__text__wrapper {
  background-color: #024c7b;
  padding: 2.4rem 3.2rem 3.2rem 2.4rem;
  color: #fff;
  min-width: 37.5rem;
}
.hero--homepage__text__wrapper p:last-of-type {
  padding-bottom: 1.6rem;
}

.hero--homepage__headline {
  padding-top: 1.6rem;
}

.hero__button.button, .hero__button.button:visited {
  font-weight: 400;
}
.hero__button.button:hover, .hero__button.button:visited:hover {
  color: #247893;
}

.hero--explorer .wdgt-hero {
  height: 100%;
  width: 100%;
}

[data-search-page] .card.detail ul, [data-search-page] .card.detail ol {
  padding-left: 0;
}
[data-search-page] .card.detail .results__info-list {
  padding-left: 0;
}
@media (max-width: 991px) {
  [data-search-page] .row-reverse {
    flex-direction: column-reverse;
  }
}
[data-search-page] .search__autocomplete {
  margin-right: 0.8rem;
}
[data-search-page] .show-more-link .fa,
[data-search-page] .show-less-link .fa {
  padding-left: 0.5rem;
}

.search--main {
  padding-top: 1.6rem;
  padding-bottom: 3.2rem;
  border-bottom: 1px solid #ccc;
}

.search__header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.search__header + .search__header {
  padding: 3.2rem 0.4rem 0 0;
}

.search__header--item {
  padding-right: 0.4rem;
}

.search-bar__wrapper {
  flex: 0 0 65%;
  max-width: 65%;
}
@media (max-width: 1279px) {
  .search-bar__wrapper {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.search-bar .search-bar__textbox {
  width: 100%;
  height: 3.4rem;
  padding: 0.8rem 1.6rem;
  line-height: 1.5;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  font-size: 1.4rem;
}
.search-bar .search-page__search-bar {
  display: flex;
  justify-content: center;
  align-items: center;
}
.search-bar .search-bar__button {
  height: 100%;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  font-weight: 400;
}