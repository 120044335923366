img.imgLeft,
img.figure--left{
	float: left;
	max-width: 38.4rem;
	margin-right: 1.2rem;
	height:auto;
}

img.imgRight,
img.figure--right{
	float: right;
	max-width: 38.4rem;
	margin-left: 1.2rem;
	height:auto;
}

.red-text {
	&,
	a {
		color: $link-red;
	}
}