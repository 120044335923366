
[v-cloak]{
	display: none;
}

.bgImg--cover {
	background-size: cover;
	background-position: 50% 50%;
}

.hiddeng {
	display: none;
}

.container {
	@media(min-width: $breakpoint-min-sm){
		max-width: 75rem;
	}

	@media(min-width: $min-992) {
		max-width: 97rem;
	}

	@media(min-width: $min-1200) {
		max-width: 117rem;
	}

	@media (min-width: $breakpoint-min-lg) {
		max-width:120rem;
	}

	@media (max-width: $breakpoint-max-sm){
		max-width: none;
	}

}

.container-back {
	background-color: #ddd;
	padding: $padding-base;
}


.scrollable {
	overflow:auto;
}


.hidden {
	display: none !important;
}

.hidden-xs {
	@media (max-width: $breakpoint-max-sm) {
		display: none !important;
	}
}

.hidden-md {
	@media (min-width: $min-1200) {
		display: none !important;
	}
}
.hidden-md-down {
	@media (max-width: $max-1199) {
		display: none !important;
	}
}
.hidden-1366-down {
	@media (max-width: $max-1365) {
		display: none !important;
	}
}

.hidden-1366 {
	@media (min-width: $min-1366) {
		display: none !important;
	}
}

.pad-4 {
	margin-left:.4rem;
	margin-right:.4rem;
}