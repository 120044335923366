﻿/// <reference path=""../../base"" />

/// Search Autocomplete
.search__autocomplete {
    flex: 1;
    position: relative;
}

// .form-control.search__input {
//   padding-left:1.6rem;
//   padding-right:1.6rem;
//   font-size:1.6rem;
// }

//.dropdown-menu.autocomplete-results {
//    position: absolute;
//    list-style: none;
//    padding: 0;
//    background-color: #fff;
//    z-index: 1;
//    border: 1px solid $grey-warm;
//    width: 100%;
//    box-shadow: 0 3px 8px 0 rgba(0,0,0,0.2), 0 0 0 1px rgba(0,0,0,0.08);
//    font-size: 1.6rem;
//
//    li {
//        -webkit-user-select: none;
//        -moz-user-select: none;
//        -ms-user-select: none;
//        user-select: none;
//        padding: 0 1.6rem;
//
//        &:hover {
//            background-color: $grey-warm;
//        }
//    }
//}

// HEADER styling

//.bf-dropdown-menu {
//    position: absolute;
//    top: 100%;
//    left: 0;
//    z-index: 1000;
//    display: none;
//    float: left;
//    min-width: 10rem;
//    padding: .5rem 0;
//    margin: .125rem 0 0;
//    font-size: 1rem;
//    color: #212529;
//    text-align: left;
//    list-style: none;
//    background-color: #fff;
//    background-clip: padding-box;
//    border: 1px solid rgba(0,0,0,.15);
//    border-radius: .25rem;
//
//    &.show {
//        display: block;
//    }
//}

[data-header] {
    .row {
        margin-left: 0;
        margin-right: 0;
    }

    .nav {
        .bf-dropdown-menu,
        .dropdown-menu {
            a {
                display: block;
                padding-top: 0;
            }
        }
    }

    .nav__link {
        padding: $padding-base * 0.5;

        &:not(.button), {
            color: $header-text;
        }
    }

    .l1__item {
        //font-family: 'Open Sans';

        &.search {
            margin-bottom: 0;
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            align-items: center;
        }

        &:not(.search) {
            font-size: 1.5rem;

            &:last-child {
                padding-right: 0;
            }

            padding: $padding-base * 0.5;

            @media (max-width: $max-1365) {
                padding: 0;

                > a {
                    padding: $padding-base;
                    display: block;
                    font-size: 1.8rem;
                    color: #000;
                }

                &.dropdown,
                &.bf-dropdown,
                &.utility {
                    font-weight: 700;
                }
            }
        }

        &.account__item {
            padding: 0.8rem 0.2rem;
        }
    }

    .container {
        padding: 0;
    }

//    .header--editions {
//        @media(min-width:$min-1366) {
//            .navbar-collapse {
//                border-bottom: 1px solid $grey-warm;
//            }
//        }
//
//        &:not(.homepage) {
//            display: none;
//        }
//
//        border-top: 3px solid $secondary-grey;
//
//        .nav {
//            li {
//                &.selected {
//                    border-top: 3px solid $secondary-grey;
//
//                    @media(max-width:$max-1365) {
//                        border-top: none;
//                    }
//                }
//            }
//
//            @media(max-width: $max-1365) {
//                &.show {
//                    li {
//                        &.selected {
//                            border-top: 3px solid $secondary-grey;
//                        }
//                    }
//                }
//            }
//        }
//
//        .mobile-header {
//            span {
//                font-size: 2rem;
//                font-weight: 600;
//                color: $header-text;
//            }
//
//            border-bottom: 1px solid $grey-warm;
//        }
//
//        .editions-nav {
//            padding-top: 0;
//
//            .container {
//                justify-content: flex-start;
//            }
//
//            &.collapse {
//                &.in,
//                &.show {
//                    border-bottom: 1px solid $grey-warm;
//                }
//            }
//        }
//    }
//
//    .header--utility {
//        padding-top: $padding-base;
//        padding-bottom: $padding-base;
//
//        &__controls {
//            padding-left: 0;
//            padding-right: 0;
//            display: flex;
//            flex-flow: row wrap;
//            justify-content: flex-end;
//            gap: 1.5rem;
//
//            .row {
//                justify-content: flex-end;
//                position: relative;
//            }
//        }
//
//        &__search {
//            &--footer {
//                background-color: $table-hover-grey;
//            }
//        }
//    }
//
//    .utility__links {
//        .nav {
//            position: static;
//            padding-bottom: 0;
//
//            li,
//            a {
//                position: static;
//            }
//
//            @media (min-width: $min-1366) {
//                height: 50px;
//            }
//        }
//    }
//
//    .utility__search {
//        align-items: flex-start;
//        padding-top: 0.8rem;
//        flex-basis: 100%;
//
//        #bs-nav-input {
//            position: relative;
//        }
//    }
//
//    .utility__link {
//        padding: $padding-base * 0.25;
//        font-size: 1.5rem;
//
//        &:not(.button) {
//            color: $link-red;
//
//            &:hover,
//            &:active,
//            &:focus {
//                color: $cta-arrow-bg-hover;
//            }
//        }
//
//        @media (min-width: $min-1366) {
//            text-transform: uppercase;
//        }
//    }
//
//    .account__item {
//        &.separator {
//            padding: 0;
//        }
//
//        .utility__link {
//            padding: 0.2rem;
//        }
//    }

    .actuarial-dropdown {
        top: calc(100% - 1.6rem);
        width: 40rem;
        left: 30%;
        font-size: 1.4rem;
        border-bottom: 5px solid $secondary-grey;
        padding-top: $padding-base;

        .form-group {
            padding-bottom: 0.8rem;
        }

        .search-by {
            &__label {
                font-weight: bold;

                i {
                    color: $secondary-green;
                    padding-right: 0.4rem;
                }
            }
        }

        .button {
            padding: .6rem $padding-base * 1.5;
            font-weight: 400;
        }

        .form--footer {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        a.form--header__link {
            padding-bottom: $padding-base;
        }

        a.form--footer__link,
        a.form--header__link {
            display: inline-block;
            color: $link-default;

            &:hover {
                color: $link-hover;
            }
        }
    }

    .search-bar {
        justify-content: flex-end;
        width: 68%;

        input {
            border-radius: 0;
            height: 100%;
        }

        @media (max-width: $max-1365) {
            width: 100%;
        }
    }

    .input-group-addon {
        color: $header-text-light;

        button {
            color: $header-text-light;
        }
    }

    .collapse {
        transition: $transition--extraEaseInOut;
        height: auto;

        &.in,
        &.show {
            max-height: 10000px;
        }

        &:not(.show):not(.in) {
            max-height: 0;
            display: block;
            overflow: hidden;
        }
    }

    .collapse,
    .collapse:not(.show):not(.in) {
        @media (min-width: $min-1366) {
            height: auto;
            max-height: 10000px;
            display: block;
        }
    }
}

.search-bar {
    position: relative;
}

.search-bar__form {
    display: flex;

    .input-group-addon {
        border-radius: 0;
    }

    flex: 1;
    font-size: 1.4rem;
}

#page {
    left: 0px;
    transition: $transition-universal;
}

.nav {
    list-style: none;
    display: block;

    li {
        display: inline-block;
    }

    &.mobile-header {
        @media (max-width: $breakpoint-max-sm) {
            padding: 0;
        }

        @media (min-width: $min-1366) {
            display: none !important;
        }

        padding: 1rem;

        button {
            padding: 0.8rem 1.2rem;
            margin: 0 0.8rem;
            background-color: $mid-grey;
            border-color: $mid-grey;

            &:hover {
                background-color: $grey-border;
                border-color: $faded-orange;
            }
        }
    }

    &.sidebar-md {
        @media (max-width: $max-1365) {
            position: fixed;
            display: flex;
            flex-direction: column;
            width: 250px;
            left: -250px;
            top: 0;
            bottom: 0;
            transition: $transition-universal;
            flex-wrap: nowrap;
            overflow-y: auto;
        }

        .search-link {
            padding-inline: 1.6rem;
        }

        button {
            &.close {
                padding: 1rem 1.6rem;
                color: #fff;
                text-shadow: none;
                opacity: 1;
                min-height: 3.6rem;
                max-width: 80%;
            }
        }

        &.show {
            left: 0;
            z-index: 10;
        }
    }
}

.input-group-addon {
    padding: 0.6rem 1.2rem;
    background-color: $grey-border;

    &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    &.search-button {
        border-radius: 0;
        background-color: #fff;
        border: 1px solid $mid-grey;
        border-left: none;
        transition: $transition-universal;

        button {
            transition: $transition-universal;
            background-color: #fff;
            border: none;
        }

        &:hover {
            background-color: #eee;

            button {
                background-color: #eee;
            }
        }
    }
}

.form-control {
    height: 3.4rem;
    font-size: 1.4rem;
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
    border: 1px solid $grey-border;
}

.bf-dropdown-menu,
.dropdown-menu {
    width: 100%;
    font-size: 1.6rem;
    padding: $padding-base * 2 $padding-base * 2 $padding-base * 1.5;
}

//@media(min-width:$min-1366) {
//    .nav--l1__dropdown--row {
//        display: block;
//
//        [class^='col-'] {
//            display: inline-block;
//            float: left;
//        }
//
//        .nav--l1__dropdown--section {
//            position: relative;
//            max-width: 16.666667%;
//            width: 100%;
//            padding-right: 15px;
//            padding-left: 15px;
//            float: left;
//        }
//    }
//}


//@media(max-width:$breakpoint-max-sm) {
//    .mobile-header__button--hamburger {
//        padding: .4rem 1rem;
//
//        i {
//            vertical-align: middle;
//        }
//    }
//}

//logo should stay fixed
//img.logo__img {
//    width: 287px;
//    height: 100px;
//}


/// DROPDOWN MY SOA
//.dropdown--mySoa {
//    @media (min-width: $breakpoint-min-sm) {
//        display: none !important;
//    }
//
//    width: 100%;
//    color: #fff;
//    text-align: right;
//    //next parent must be relative in this case
//    position: static;
//
//    .dropdown-toggle {
//        position: absolute;
//        right: 0;
//        top: 50%;
//        transform: translateY(-50%);
//        padding-right: 1.6rem;
//        text-transform: uppercase;
//    }
//
//    .dropdown--mySoa__toggle {
//        i {
//            margin-left: .4rem;
//        }
//    }
//
//    .dropdown-menu {
//        background-color: $primary-blue-1;
//        transform: translateY(4.6rem) !important;
//        padding: 0;
//        border: none;
//
//        .dropdown-menu__link {
//            text-transform: none !important;
//            border-bottom: 1px solid $account-hover;
//            padding: 1.6rem 2rem !important;
//
//            &:hover,
//            &:active,
//            &:focus {
//                background-color: $account-hover;
//            }
//        }
//    }
//
//    .dropdown-menu__listItem {
//        display: block;
//    }
//
//    .dropdown-menu__listItem--hasButton {
//        text-align: right;
//        padding: 1.6rem;
//    }
//
//    .button--logout--mySoa {
//        html body .nav--main__wrapper & {
//            display: inline-block;
//            padding: .8rem 1.6rem;
//            text-transform: none;
//        }
//
//        &:hover,
//        &:active,
//        &:focus {
//            html body .nav--main__wrapper & {
//                background: #fff;
//                color: $primary-blue-1;
//            }
//        }
//    }
//}

/// HERO
//this is how we scope
[data-homepageheroblock] {
    h1 {
        color: #fff;
    }
}

.row--hero {
    width: 100%;

    @media (max-width: $max-991) {
        margin: 0;
    }
}

.column--hero {
    @media (max-width: $max-991) {
        padding: 0;
    }
}

.hero__image__wrapper {
    padding: $padding-base;
    min-height: 40rem;
    height: 1px;
    display: flex;
    align-items: center;
    position: relative;

    @media (max-width: $breakpoint-max-sm) {
        padding: 0;
        min-height: 0;
        height: auto;

        &[style] {
            // lazy load takes care of this, but only on page load
            // in case of resize, force this
            background-image: none !important;
        }
    }

    .hero--explorer & {
        padding: 0;
    }

    &.page-edit-pseudo {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}

.hero--homepage__text__wrapper {
    background-color: $primary-blue-1;
    padding: $padding-base * 1.5 $padding-base * 2 $padding-base * 2 $padding-base * 1.5;
    color: #fff;
    min-width: 37.5rem;

    p {
        &:last-of-type {
            padding-bottom: $padding-base;
        }
    }
}

.hero--homepage__headline {
    padding-top: $padding-base;
}

.hero__button {
    &.button {
        &,
        &:visited {
            font-weight: 400;

            &:hover {
                color: $cta-arrow-bg-hover;
            }
        }
    }
}

.hero--explorer {
    .wdgt-hero {
        height: 100%;
        width: 100%;
    }
}
///// Search
[data-search-page] {
    .card {
        &.detail {
            ul, ol {
                padding-left: 0;
            }

            .results__info-list {
                padding-left: 0;
            }
        }
    }

    .row-reverse {
        @media (max-width: $max-991) {
            flex-direction: column-reverse;
        }
    }

    .search__autocomplete {
        margin-right: $padding-base * 0.5;
    }

    .show-more-link,
    .show-less-link {
        .fa {
            padding-left: 0.5rem;
        }
    }
}

.search--main {
    padding-top: $padding-base;
    padding-bottom: $padding-base * 2;
    border-bottom: 1px solid $grey-border;
}


.search__header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    + .search__header {
        padding: $padding-base * 2 $padding-base * 0.25 0 0;
    }
}

.search__header--item {
    padding-right: $padding-base * 0.25;
}

.search-bar__wrapper {
    flex: 0 0 65%;
    max-width: 65%;

    @media (max-width: $breakpoint-max-lg) {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

.search-bar {
    .search-bar__textbox {
        width: 100%;
        height: 3.4rem;
        padding: $padding-base * 0.5 $padding-base;
        line-height: 1.5;
        color: #555;
        background-color: #fff;
        background-image: none;
        border: 1px solid $grey-border;
        border-radius: 4px;
        box-shadow: inset 0 1px 1px rgba(#000,.075);
        font-size: 1.4rem
    }

    .search-page__search-bar {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .search-bar__button {
        height: 100%;
        padding-top: .6rem;
        padding-bottom: .6rem;
        font-weight: 400;
    }
}

//#headerNav {
//    position: sticky;
//    top: 0;
//    z-index: 2;
//
//    > .container {
//        padding: 0;
//    }
//
//    .main-navbar {
//        padding: 0px 16px;
//        list-style-type: none;
//        background-color: $secondary-gray;
//        display: flex;
//        position: relative;
//
//        &__item {
//            padding: 8px 16px;
//
//            &:hover {
//                background-color: $off-white;
//            }
//
//            > a {
//                &::after {
//                    display: none;
//                }
//            }
//
//            .bf-dropdown-menu {
//                padding: 3.2rem 3.2rem 2.4rem;
//                border: none;
//                box-shadow: 0 6px 12px rgba(0,0,0,.175);
//                margin: 0;
//
//                .l2__headline {
//                    padding: 0.8rem 0;
//                    font-size: 1.6rem;
//                    color: $header-text;
//
//                    > a {
//                        color: $header-text;
//                    }
//                }
//
//                .nav--l1__dropdown--section {
//                    padding: 0px 15px;
//
//                    .header--nav__main--l2 {
//                        > .nav__link {
//                            padding-bottom: 8px;
//                            font-size: 1.5rem;
//                            display: block;
//                            color: $header-text;
//                        }
//                    }
//                }
//            }
//        }
//
//        .main-navbar-dropdown {
//            display: none;
//        }
//    }
//
//    .mobile-header {
//        background-color: $secondary-gray;
//        display: flex;
//        flex-wrap: nowrap;
//        padding: 8px;
//    }
//
//    @media (max-width: $max-1365) {
//        margin-left: 0;
//        margin-right: 0;
//    }
//}

//.mobile-nav-sidebar {
//    position: fixed;
//    left: 0;
//    top: 0;
//    height: 0;
//    overflow: hidden;
//    width: 100vw;
//    display: flex;
//
//    &.show {
//        z-index: 3;
//        height: 100%;
//
//        .mobile-nav-overlay {
//            display: block !important;
//        }
//    }
//
//    .mobile-nav-overlay {
//        display: none;
//        flex-grow: 1;
//        background-color: black;
//        opacity: 0.3;
//        z-index: 20000;
//        pointer-events: auto;
//    }
//
//    .mobile-nav-wrap {
//        width: 250px;
//        height: 100%;
//        overflow-y: auto;
//        background-color: $secondary-gray;
//        pointer-events: auto;
//
//        .mobile-nav-list {
//            list-style-type: none;
//            display: flex;
//            flex-direction: column;
//            width: 100%;
//            /*
//            width: 250px;
//            position: fixed;
//            top: 0;
//            max-height: 100%;
//            overflow-y: auto;
//            */
//    
//            .search {
//                display: flex !important;
//                width: 100%;
//    
//                .close {
//                    display: block;
//                    flex-grow: 1;
//                    padding: 10px 16px;
//                    color: #fff;
//                    text-align: right;
//                }
//    
//                .search-link {
//                    padding: 10px 16px;
//                }
//            }
//    
//            .l1__item {
//                font-family: 'Open Sans';
//                width: 100%;
//                display: block;
//    
//                > .nav__link, > .utility__link {
//                    width: 100%;
//                    display: block;
//                    padding: 16px;
//    
//                    font-size: 1.8rem;
//                    font-weight: 700;
//                    color: #000;
//                }
//            }
//    
//            .utility-hr {
//                border-bottom: 1px solid $grey-warm;
//                margin: 1.6rem 0;
//            }
//    
//            .bf-dropdown-menu {
//                position: static;
//                padding: 32px;
//    
//                .l2__headline {
//                    padding: 8px 0px;
//                    font-size: 1.6rem;
//                    font-family: 'Open Sans';
//    
//                    > a {
//                        color: $headline-color;
//                    }
//                }
//    
//                .header--nav__main--l2 {
//                    > .nav__link {
//                        font-family: 'Open Sans';
//                        color: $header-text;
//                        font-size: 1.5rem;
//                        display: block;
//                        padding-bottom: 8px;
//                        font-weight: 700;
//                    }
//    
//                    padding-bottom: 4px;
//                }
//            }
//        }
//    }
//}

//.my-soa--logged-in__dropdown {
//    .dropdown-menu__listItem {
//        display: block;
//        width: 100%;
//
//        > .dropdown-menu__link {
//            display: block;
//            width: 100%;
//            padding: 16px 20px;
//            
//            color: #fff;
//        }
//    }
//}
//
//#mySoaDropdownButton {
//    &::after {
//        display: none;
//    }
//}

//.scroll-locked {
//    overflow: hidden;
//    pointer-events: none;
//}